var exports = {};
exports = {
  A: {
    D: {
      "1": "UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "0 1 2 3 4 5 6 7 8 9 I r J D E F A B C K L G M N O s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB"
    },
    L: {
      "1": "H"
    },
    B: {
      "1": "P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H",
      "2": "C K L G M N O"
    },
    C: {
      "1": "9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB I r 8B 9B",
      "33": "0 1 2 3 4 5 6 7 8 J D E F A B C K L G M N O s t u v w x y z"
    },
    M: {
      "1": "c"
    },
    A: {
      "2": "J D E F A B 6B"
    },
    F: {
      "1": "HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "0 1 2 3 4 5 6 7 8 9 F B C G M N O s t u v w x y z AB BB CB DB EB FB GB LC MC NC OC nB 4B PC oB"
    },
    K: {
      "1": "d",
      "2": "A B C nB 4B oB"
    },
    E: {
      "1": "K L G oB yB HC IC zB 0B 1B 2B pB 3B JC",
      "2": "I r J D CC wB DC EC FC KC",
      "33": "E F A B C GC xB nB"
    },
    G: {
      "1": "cC dC eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "2": "wB QC 5B RC SC TC",
      "33": "E UC VC WC XC YC ZC aC bC"
    },
    P: {
      "1": "uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "2": "I sC tC"
    },
    I: {
      "1": "H",
      "2": "qB I lC mC nC oC 5B pC qC"
    }
  },
  B: 6,
  C: "text-decoration-line property"
};
export default exports;